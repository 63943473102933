$blue: #1980B6;
//
//body, html{
//  height:100%;
//  #wrapper{
//    height: 100%;
//    //overflow:auto;
//  }
//}

//.content-wrapper{
//  display: flex;
//  flex-direction: column;
//  height: 100%;
//}




.primary-color{
  &_bg{
    background: $blue !important;
  }
  &_text{
    color: $blue !important;
  }
}

.heading-title.heading-arrow-bottom:after{
  border-top-color: $blue !important;
}



.box-icon .box-icon-title>h2{
	line-height: 22px;
	margin-top: 7px;
}

.bgicons1{
	background: url('../images/bg_icons.png') repeat;
	max-width:100%;
	-webkit-background-size: auto !important;
	background-size: auto !important;
}

.footer_icons{
	height: 60px;
	width: 100%;
	background: url('../images/footer_icons.png') repeat-x center;
}



section.nopadding{
	&:last-child{
      border-bottom:none !important;
	 }
}
